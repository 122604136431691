import { useAccount, useDisconnect } from 'wagmi';

import { useWeb3Modal, useWeb3ModalState } from '@web3modal/wagmi/react';
import { useEffect, useState } from 'react';
import { toastSuccess } from '@shared/utils';

const useConnectWallet = () => {
  const { open } = useWeb3Modal();
  const { open: isOpen } = useWeb3ModalState();
  const { disconnectAsync } = useDisconnect();
  const { address, isReconnecting, isConnected, isConnecting } = useAccount();
  const [checkConnect, setCheckConnect] = useState(false);

  const handleConnect = async () => {
    // AM-1185
    // - only able to click if not reconnecting wallet
    // - if edge case happens that address exists but login modal didn't open, on click first disconnect the connected wallet, and then open web3modal
    !!address && (await disconnectAsync());
    await disconnectAsync();
    open();
  };

  useEffect(() => {
    if (isConnecting) setCheckConnect(true);
    else if (checkConnect && !isConnecting && isConnected) {
      toastSuccess('Connect Wallet successfully');
      setCheckConnect(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isConnecting, isConnected])

  return {
    handleConnect,
    isReconnecting,
    isOpen,
  };
};

export default useConnectWallet;
