import {
  AuthChangePasswordData,
  AuthChangePasswordResponseData,
  AuthResetPasswordData,
  AuthResetPasswordResponseData,
  AuthResponse,
  ConfirmEmailData,
  ConfirmEmailResponse,
  GetSigninMessageParams,
  GetSigninMessageResponse,
  IRequestConfirmEmailResponse,
  LoginWithWalletData,
  RegisterWitWalletData,
  RequestOtpCodeData,
  UserConfirmEmailData,
  UserConfirmEmailResponse,
  UserGetMeResponse,
  UserLoginData,
  UserLoginOtpData,
  UserLoginResponse,
  UserLoginWithGGData,
  UserRegisterData,
  UserRegisterWithGGData,
  UserRequestOtpData,
} from '@interfaces/auth';

import {
  withAuthenticatedClientRequest,
  withClientRequest,
} from '@api/requestBuilder/client/withClientRequest';
import { withAuthenticatedServerRequest } from '@api/requestBuilder/server/withServerRequest';
import { ICallableRequestBuilder } from '@api/requestBuilder/types';

import config from '@shared/config';
import { generateUrlParams } from '@shared/utils/urlString';

const loginWithWallet =
  (request: ICallableRequestBuilder<AuthResponse>) =>
    (data: LoginWithWalletData): Promise<AuthResponse> => {
      return request.call(
        `${config.userApiUrl}/api/v1/user/auth/login`,
        (init) => ({
          ...init,
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
        })
      );
    };

const registerWithWallet =
  (request: ICallableRequestBuilder<AuthResponse>) =>
    (data: RegisterWitWalletData): Promise<AuthResponse> => {
      return request.call(
        `${config.userApiUrl}/api/v1/user/auth/register`,
        (init) => ({
          ...init,
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
        })
      );
    };

const confirmEmail =
  (request: ICallableRequestBuilder<ConfirmEmailResponse>) =>
    (data: ConfirmEmailData): Promise<ConfirmEmailResponse> => {
      return request.call(
        `${config.userApiUrl}/api/v1/user/auth/email/confirm`,
        (init) => ({
          ...init,
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
        })
      );
    };

const requestOtpCode =
  (request: ICallableRequestBuilder<IRequestConfirmEmailResponse>) =>
    (data: RequestOtpCodeData): Promise<IRequestConfirmEmailResponse> => {
      return request.call(
        `${config.userApiUrl}/api/v1/user/auth/email/request_confirm_email`,
        (init) => ({
          ...init,
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
        })
      );
    };

const getSigninMessage =
  (request: ICallableRequestBuilder<GetSigninMessageResponse>) =>
    async (params: GetSigninMessageParams) => {
      const paramString = generateUrlParams(params);
      return request.call(
        `${config.userApiUrl}/api/v1/user/auth/signin-message?${paramString}`
      );
    };

const getMe =
  (request: ICallableRequestBuilder<UserGetMeResponse>) => async () => {
    return request.call(`${config.userApiUrl}/api/v1/user/auth/me`);
  };

const loginOtp =
  (request: ICallableRequestBuilder<IRequestConfirmEmailResponse>) =>
    (data: UserLoginOtpData): Promise<IRequestConfirmEmailResponse> => {
      return request.call(
        `${config.userApiUrl}/api/v1/user/auth/login_otp`,
        (init) => ({
          ...init,
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
        })
      );
    };


const userLogin =
  (request: ICallableRequestBuilder<UserLoginResponse>) =>
    (data: UserLoginData): Promise<UserLoginResponse> => {
      return request.call(
        `${config.userApiUrl}/api/v1/user/auth/login`,
        (init) => ({
          ...init,
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
        })
      );
    };


const userRegister =
  (request: ICallableRequestBuilder<AuthResponse>) =>
    (data: UserRegisterData): Promise<AuthResponse> => {
      return request.call(
        `${config.userApiUrl}/api/v1/user/auth/register`,
        (init) => ({
          ...init,
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
        })
      );
    };

const userRegisterWithGG =
  (request: ICallableRequestBuilder<AuthResponse>) =>
    (data: UserRegisterWithGGData): Promise<AuthResponse> => {
      return request.call(
        `${config.userApiUrl}/api/v1/auth/google/register`,
        (init) => ({
          ...init,
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
        })
      );
    };

const userLoginWithGG =
  (request: ICallableRequestBuilder<AuthResponse>) =>
    (data: UserLoginWithGGData): Promise<AuthResponse> => {
      return request.call(
        `${config.userApiUrl}/api/v1/auth/google/login`,
        (init) => ({
          ...init,
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
        })
      );
    };

const userRegisterConfirmEmail =
  (request: ICallableRequestBuilder<UserConfirmEmailResponse>) =>
    (data: UserConfirmEmailData): Promise<UserConfirmEmailResponse> => {
      return request.call(
        `${config.userApiUrl}/api/v1/user/auth/confirm`,
        (init) => ({
          ...init,
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
        })
      );
    };

const userRequestResendOtp =
  (request: ICallableRequestBuilder<UserConfirmEmailResponse>) =>
    (data: UserRequestOtpData): Promise<UserConfirmEmailResponse> => {
      return request.call(
        `${config.userApiUrl}/api/v1/user/auth/request_confirm_email`,
        (init) => ({
          ...init,
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
        })
      );
    };

const userForgotPassword =
  (request: ICallableRequestBuilder<UserConfirmEmailResponse>) =>
    (data: UserRequestOtpData): Promise<UserConfirmEmailResponse> => {
      return request.call(
        `${config.userApiUrl}/api/v1/user/auth/forgot`,
        (init) => ({
          ...init,
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
        })
      );
    };

const userResetPassword =
  (request: ICallableRequestBuilder<AuthResetPasswordResponseData>) =>
    (data: AuthResetPasswordData): Promise<AuthResetPasswordResponseData> => {
      return request.call(
        `${config.userApiUrl}/api/v1/user/auth/reset_password`,
        (init) => ({
          ...init,
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
        })
      );
    };

const userChangePassword =
  (request: ICallableRequestBuilder<AuthChangePasswordResponseData>) =>
    (data: AuthChangePasswordData): Promise<AuthChangePasswordResponseData> => {
      return request.call(
        `${config.userApiUrl}/api/v1/user/auth/change_password`,
        (init) => ({
          ...init,
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
        })
      );
    };

export const userAuthApi = {
  client: {
    loginWithWallet: withClientRequest(loginWithWallet),
    registerWithWallet: withClientRequest(registerWithWallet),
    getSigninMessage: withClientRequest(getSigninMessage),
    confirmEmail: withClientRequest(confirmEmail),
    requestOtpCode: withClientRequest(requestOtpCode),
    loginOtp: withClientRequest(loginOtp),
    login: withClientRequest(userLogin),
    getMe: withAuthenticatedClientRequest(getMe),
    userRegister: withClientRequest(userRegister),
    userRegisterWithGG: withClientRequest(userRegisterWithGG),
    userLoginWithGG: withClientRequest(userLoginWithGG),
    userRegisterConfirmEmail: withClientRequest(userRegisterConfirmEmail),
    userRequestResendOtp: withClientRequest(userRequestResendOtp),
    userForgotPassword: withClientRequest(userForgotPassword),
    userResetPassword: withClientRequest(userResetPassword),
    userChangePassword: withClientRequest(userChangePassword),
  },
  server: {
    getMe: async () => {
      try {
        const response = await withAuthenticatedServerRequest(getMe)();
        return response;
      } catch (e) {
        return null;
      }
    },
  },
};

